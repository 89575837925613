<template>
  <div class="dialog-container">
    <h2 class="dialog-title">问答系统</h2>
    <div class="title-bottom"></div>
    <v-container>
      <v-content v-scroll="onScroll">
        <div class="scroll-container">
          <div class="chatArea">
            <div
              v-for="(item, index) in showMsg"
              :key="index"
              :class="{
                user: item.role === 'user',
                robot: item.role != 'user',
              }"
            >
              <div>
                <v-row v-if="item.role === 'user'">
                  <div class="rightp">
                    <span class="nickname right">{{ item.role }}</span>
                    <div class="content bg-green">
                      <span>{{ item.content }}</span>
                    </div>
                  </div>
                  <img src="@/assets/images/human.png" class="head righti" />
                </v-row>
                <v-row v-else-if="item.role != 'user'">
                  <img src="@/assets/images/robot.png" class="head lefti" />
                  <div>
                    <span class="nickname">{{ item.role }}</span>
                    <div class="content bg-white">
                      <span>{{ item.content }}</span>
                    </div>
                  </div>
                </v-row>
              </div>
            </div>
          </div>
        </div>
      </v-content>
      <div class="input-area">
        <v-row style="height: 90%;">
          <input v-model="sendMsg" class="dialog-input" type="text"/>
          <button
            @click="send"
            @keydown.enter="send"
            class="dialog-btn"
          ></button
        ></v-row>
      </div>
      <template>
        <v-snackbar v-model="questionEmpty" top :timeout="3000">
          消息内容不能为空，请重新输入
          <template v-slot:action="{ attrs }">
            <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="questionEmpty = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </template>
      <LoginDialog :dialog="dialog" />
    </v-container>
  </div>
</template>

<script>
import axios from '@/service'
import LoginDialog from '@/views/components/LoginDialog.vue'
import { getToken } from '@/utils'
export default {
  data() {
    return {
      showMsg: [
        {
          role: 'robot',
          content: '你好，我是AI小助手，有什么可以帮助您的吗？',
        },
      ],
      sendMsg: '',
      questionEmpty: false,
      dialog: false,
    }
  },
  components: {
    LoginDialog,
  },
  methods: {
    async send() {
      // 添加消息
      if (this.sendMsg) {
        let umsg = { role: null, content: null }
        umsg.role = 'user'
        umsg.content = this.sendMsg
        this.showMsg.push(umsg)
        this.sendMsg = ''
        const {
          data: { data },
        } = await axios.get(
          `http://47.110.126.186:3010/find/getAnswer?question=${umsg.content}`,
          {
            headers: {
              Authorization: `${getToken()}`,
            },
          },
        )
        console.log(data)
        let rmsg = { role: 'robot', content: '抱歉，未查到答案' }
        if (data != null) {
          rmsg.role = data.business
          rmsg.content = data.answer
        }
        this.showMsg.push(rmsg)
      } else {
        this.questionEmpty = true
      }
      var hdiv = document.getElementById('hidden-div')
      hdiv.scrollIntoView()
    },
  },
  mounted() {
    if (!getToken()) {
      this.dialog = true
    }
  },
}
</script>

<style scoped>
html overflow .scroll-container {
  height: 100% overflow-y scroll backface-visibility;
}

.dialog-container {
  position: relative;
  top: 10rem;
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: #e5e5e5;
  padding-top: 5%;
  /* 1.875rem */
  padding-bottom: 15%;
  /* 20rem */
  overflow-y: scroll;
}

.dialog-title {
  text-align: center;
  padding-top: 20px;
  font-size: 3.8rem;
  font-weight: 545;
  letter-spacing: 36;
  font-family: 'microsoft yahei';
  line-height: 3.4375rem;
}
.title-bottom {
  background-color: #fca311;
  width: 20%;
  border-radius: 2rem;
  height: 1.5rem;
  margin: auto;
  margin-top: -0.625rem;
}
/* .title-bottom:hover {
  margin-top: -4.8em;
  height: 6rem;
} */
.input-area {
  position: fixed;
  bottom: 10%;
  left: 15%;
  width: 68.297%;
  height: 20%;
  /* 65rem */
  background-color: #fecc79;
  border-top-left-radius: 4rem;
  border-top-right-radius: 3rem;
  padding-top: 2.5%;
  /* 4rem; */
  padding-left: 3%;
  box-shadow: 3px 2px 5px #8c8c8c;
}
.dialog-input {
  width: 80%;
  /* 54rem */
  height: 80%;
  /* background-size: 100% 100%; */
  background-color: #fff5e4;
  border: 0.1rem solid #fff5e4;
  border-radius: 1rem;
  font-size: 1.8rem;
  outline: none;
  padding-left: 3%;
  /* 1.25rem */
  color: #000000;
}
.dialog-btn {
  background: url('../assets/images/dialog-sent.png');
  background-repeat: no-repeat;
  background-position: 0px -15px;
  height: 120%;
  width: 10%;
  /* 7rem */
  background-size: 100% 100%;
  margin-left: 0.25rem;
  outline: none;
}
.user {
  margin-bottom: 10%;
}
.robot {
  margin-bottom: 5%;
}
.chatArea {
  width: 100%;
  margin-left: 20px;
}
.content {
  min-height: 30px;
  width: auto;
  min-width: 60px;
  max-width: 600px;
  padding-top: 1%;
  padding-bottom: 5px;
  padding-left: 1.5%;
  padding-right: 5px;
  font-size: 1.5rem;
  margin-left: 12.5rem;
  /* 12.5rem */
  border-radius: 0.6rem;
}
.bg-green {
  position: relative;
  background-color: #fca311;
  border: 0.125rem solid #fca311;
  opacity: 0.75;
}
.bg-green:after {
  position: absolute;
  content: '';
  left: 100%;
  top: 25px;
  border-top: 8px solid transparent;
  border-left: 12px solid #fca311;
  border-bottom: 2px solid transparent;
}
.bg-white {
  position: relative;
  background-color: #ffffff;
  /* border: 0.125rem solid #14213d; */
}
.bg-white:before {
  position: absolute;
  content: '';
  right: 100%;
  top: 25px;
  border-top: 8px solid transparent;
  border-right: 12px solid #ffffff;
  border-bottom: 2px solid transparent;
}
.head {
  width: 75px !important;
  height: 75px !important;
}
.lefti {
  position: absolute;
  left: 7.5rem;
  /* 7.5rem */
}
.righti {
  position: absolute;
  right: 7.5rem;
  /* 7.5rem */
}
.rightp {
  position: absolute;
  right: 12.6875rem;
}
.nickname {
  font-size: 1.2rem;
  color: #002752;
  margin-left: 12.5rem;
}
.right {
  display: flex;
  justify-content: flex-end;
}
</style>
